import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { withPrefix } from 'gatsby';

const Header = styled('div')``;
const Icon = styled('img')``;
export const Title = styled('h4')``;
export const Item = styled('li')``;

export const BulletList = ({
  className,
  icon,
  inverted,
  items,
  title,
  ...props
}) => (
  <article className={className} {...props}>
    {(icon || title) && (
      <Header>
        {icon && <Icon src={icon} />}
        {title && <FormattedMessage capitalize component={Title} id={title} />}
      </Header>
    )}
    <ul>
      {items.map(item => (
        <FormattedMessage
          capitalize
          component={Item}
          id={item}
          itemScope
          itemType="http://schema.org/ListItem"
          key={JSON.stringify(item)}
        />
      ))}
    </ul>
  </article>
);

BulletList.defaultProps = {
  className: '',
  inverted: false,
  items: [],
  title: '',
};

BulletList.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  inverted: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

export default styled(BulletList).attrs(props => ({
  inverted: props.inverted || false,
}))`
  ${Icon} {
    height: 1.2rem;
    margin-right: 0.8rem;
    width: 1.2rem;
  }

  ${Header} {
    display: flex;
    flex-direction: row;
  }

  ${Title} {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
    min-height: 3.2rem;
    text-align: left;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    opacity: 0.6;
    font-weight: 400;

    ${Item} {
      background-image: url('${props =>
        withPrefix(
          `/images/icons/${props.inverted ? 'check-inverted' : 'check'}.svg`,
        )}');
      background-position: 0rem 0.5rem;
      background-size: 1.2rem;
      background-repeat: no-repeat;
      font-size: 1.2rem;
      line-height: 2.2rem;
      margin-top: 0.4rem;
      padding-left: 1.8rem;
    }
  }

  @media screen and (min-width: 600px) {
    ${Icon} {
      height: 2.4rem;
      margin-right: 0.8rem;
      width: 2.4rem;
    }

    ${Title} {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
      padding-right: 1.6rem;
    }

    ul{
      ${Item}{
        background-position: 0rem 0.5rem;
        background-repeat: no-repeat;
        background-size: auto;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 1.6rem;
        margin-top: 1.6rem;
        padding-left: 3.2rem;
        padding-right: 1.2rem;
      }
    }
  }
`;
