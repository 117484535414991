import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import BulletList, { Title, Item } from 'Components/BulletList';

const Card = styled('article')``;

const Solution = ({ className, inverted, item }) => (
  <Card className={className}>
    <BulletList
      inverted={!inverted}
      title={`SOLUTIONS.${item}.TITLE`}
      items={[
        `SOLUTIONS.${item}.DESCRIPTION1`,
        `SOLUTIONS.${item}.DESCRIPTION2`,
        `SOLUTIONS.${item}.DESCRIPTION3`,
      ]}
    />
  </Card>
);

Solution.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  item: PropTypes.string,
};

export default styled(Solution)`
  align-items: ${props => (props.inverted ? 'flex-start' : 'flex-end')};
  background-color: ${props => (props.inverted ? '#f7f9fc' : '#003566')};  
  color: ${props => (props.inverted ? '#000f19' : 'white')};    
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  width: 100%;  
  ${BulletList} {
    ${Title} {
      font-size: 3.2rem;
    }  
    ${Item} {
      font-size: 2rem;
      line-height: 3.2rem;
    }
  }
  
  @media screen and (min-width: 600px) {
    background: url("${props => props.image}") center 50% / 30%
      no-repeat;
    background-color: ${props => (props.inverted ? '#f7f9fc' : '#003566')};    
    background-position-x: ${props => (props.inverted ? '85%' : '15%')};
    min-height: 60vh;
    padding: 11.2rem;
    ${BulletList} {
      width: 55%;
    }
  }
`;
