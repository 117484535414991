import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage, withIntl } from '@react-intl';

import Solution from './Solution';

const Subtitle = styled('p')``;
const Title = styled('h2')``;

const Solutions = ({ className }) => (
  <section className={className}>
    <FormattedMessage capitalize component={Title} id="SOLUTIONS.TITLE" />
    <FormattedMessage capitalize component={Subtitle} id="SOLUTIONS.SUBTITLE" />
    <Solution image="/images/backgrounds/customers.svg" item="1" />
    <Solution image="/images/backgrounds/revenues.svg" item="2" inverted />
    <Solution image="/images/backgrounds/conversion.svg" item="3" />
    <Solution image="/images/backgrounds/enterprise.svg" item="4" inverted />
  </section>
);
Solutions.propTypes = {
  className: PropTypes.string,
};

export default styled(withIntl(Solutions))`
  align-items: center;
  background-color: #003566;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6.4rem;
  ${Title} {
    font-size: 3.2rem;
    padding: 3.2rem 0;
  }
  ${Subtitle} {
    align-items: center;
    background-color: #003566;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 3.2rem;
    opacity: 0.6;
    padding: 3.2rem;
  }

  @media screen and (min-width: 600px) {
    ${Title} {
      font-size: 4.8rem;
      height: 10vh;
      margin: 11.2rem 0 6.4rem;
      padding: 0;
    }
    ${Subtitle} {
      font-size: 2.4rem;
      line-height: 4rem;
      margin-bottom: 6.4rem;
      opacity: 0.8;
      padding: 0 11.2rem;
      width: 90%;
    }
  }
`;
